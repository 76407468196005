import React from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  TextField,
} from "@mui/material";
import { addComment } from "../features/comments/api/add";
import { WordType } from "../features/words/types";

interface ContactDialogProps {
  isOpen: boolean;
  word: WordType;
  onClose: React.MouseEventHandler<HTMLElement>;
}

const ContactDialog = ({ isOpen, word, onClose }: ContactDialogProps) => {
  const [extraComment, setExtraComment] = React.useState<string>("");
  const updateExtraComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setExtraComment(event.target.value);
  };

  const send = (e: React.MouseEvent<HTMLElement>) => {
    // Send support request
    addComment({
      word: word.word,
      comment: extraComment,
      date: new Date(),
      treated: false,
    })
      .then(() => {
        console.log("Comment sent");
      })
      .catch((e) => {
        console.error("Error while sending comment");
      });
    onClose(e);
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      scroll="paper"
      onClose={onClose}
      aria-labelledby="contact-dialog-title"
    >
      <Container sx={{ p: 2 }}>
        <DialogTitle id="contact-dialog-title" textAlign="center">
          Signaler ce mot ?
        </DialogTitle>
        <Box display="flex">
          <Button
            size="large"
            sx={{ marginX: "auto", marginY: 2 }}
            onClick={send}
            color="primary"
          >
            Envoyer
          </Button>
        </Box>
        <TextField
          fullWidth
          autoFocus
          multiline
          rows={2}
          maxRows={4}
          label="Commentaire"
          value={extraComment}
          onChange={updateExtraComment}
        />
      </Container>
    </Dialog>
  );
};

export default ContactDialog;

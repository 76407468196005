
import { getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { commentsCollection } from "../../../lib/firebase/firestore";
import { CommentType } from "../types";

// export const getComments = async () => {
//   const q = query(commentsCollection, orderBy("date", "asc"), limit(10));
//   const comments: CommentType[] = [];
//   const querySnapshot = await getDocs(q);
//   querySnapshot.forEach((doc) => {
//     const docData = doc.data();
//     comments.push({
//       id: doc.id,
//       word: docData.word,
//       comment: docData.comment,
//       date: docData.date.toDate(),
//       treated: docData.treated,
//     });
//   });
//   return comments;
// };

export const getComments = async (limitNumber: number, lastVisible: any, onlyUntreated: boolean) => {
  let q;
  let constraints: any[];
  if (onlyUntreated) {
    constraints = [
      where("treated", "==", false),
      orderBy("date", "asc"),
      limit(limitNumber),
    ];
  } else {
    constraints = [
      where("treated", "==", true),
      orderBy("date", "asc"),
      limit(limitNumber),
    ];
  }
  if (lastVisible) {
    constraints.push(startAfter(lastVisible));
  }
  q = query(
    commentsCollection,
    ...constraints
  );

  const comments: CommentType[] = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const docData = doc.data();
    comments.push({
      id: doc.id,
      word: docData.word,
      comment: docData.comment,
      date: docData.date.toDate(),
      treated: docData.treated,
    });
  });

  const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  return { comments, lastDoc };
};


import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { getComments } from "../../features/comments/api/get";
import { CommentType } from "../../features/comments/types";
import { createSearchParams, useNavigate } from "react-router-dom";
import { setCommentTreated } from "../../features/comments/api/update";
import { deleteComment } from "../../features/comments/api/delete";
import BackButton from "../../components/BackButton";
import { withAuth } from "../../features/auth/components/AuthAccess";

const CommentsPage = () => {
  const commentsPerPage = 10;
  const [comments, setComments] = useState<CommentType[]>([]);
  const [lastDoc, setLastDoc] = useState<any>(undefined);
  const [treated, setTreated] = useState(false);
  const navigate = useNavigate();

  const getCommentsBatch = () => {
    return getComments(commentsPerPage, lastDoc, !treated)
      .then(({comments, lastDoc}) => {
        setLastDoc(lastDoc);
        setComments(comments);
      })
      .catch((error) => {
        console.error("Imposible to get comments", error);
      });
  };

  useEffect(() => {
    // load first comments
    getCommentsBatch();
  }, [treated]); // eslint-disable-line react-hooks/exhaustive-deps

  const editWord = (comment: CommentType) => {
    setCommentTreated(comment.id!).then(() => {
      goToWord(comment);
    });
  };

  const deleteTheComment = (comment: CommentType) => {
    deleteComment(comment.id!).then(() => {
      setComments(comments.filter((c) => c.id !== comment.id));
    });
  }

  const goToWord = (comment: CommentType) => {
    navigate({
      pathname: "/",
      search: createSearchParams({
        word: comment.word,
        back: "admin",
      }).toString(),
    });
  };

  return (
    <Box>
      <BackButton />
      <Box display="flex" alignItems="center" marginBottom={2}>
      <Switch
        checked={treated}
        onChange={(e) => {
          setTreated(e.target.checked);
          setLastDoc(undefined);
        }}
        name="onlyUntreated"
        color="primary"
      />
      <label htmlFor="onlyUntreated">Traités</label>
      </Box>
      {comments.length > 0 && (
      <Table>
        <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: "bold" }}>Mot</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Commentaire</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Corriger</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Voir mot</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Supprimer</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Traité ?</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {comments.map((comment, i) => (
          <TableRow key={"comment-" + i}>
          <TableCell>{comment.word}</TableCell>
          <TableCell>{comment.comment}</TableCell>
          <TableCell>
            {comment.date.toLocaleDateString("fr-ch") +
            " " +
            comment.date.toLocaleTimeString("fr-ch")}
          </TableCell>
          <TableCell>
            <IconButton onClick={() => editWord(comment)}>
            <Edit />
            </IconButton>
          </TableCell>
          <TableCell>
            <IconButton onClick={() => goToWord(comment)}>
            <Visibility />
            </IconButton>
          </TableCell>
          <TableCell>
            <IconButton onClick={() => deleteTheComment(comment)}>
              <Delete />
            </IconButton>
          </TableCell>
          <TableCell>
            {comment.treated ? "Traité" : "Non traité"}
          </TableCell>
          </TableRow>
        ))}
        </TableBody>
      </Table>
      )}
      <Box display="flex" justifyContent="center" marginTop={2}>
      <IconButton
        onClick={() => getCommentsBatch()}
        color="primary"
      >
        Suivant
      </IconButton>
      </Box>
    </Box>
  );
};

export default withAuth(CommentsPage);
